import React from 'react'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Toast from 'react-bootstrap/Toast'
import ToastContainer from 'react-bootstrap/ToastContainer'
import Spinner from '../../components/Spinner/Spinner'
import EditorComponent from '../../components/TextEditor/EditorComponent'
import FileInput from '../../components/Forms/FileInput'
import { submitForm } from '../../utils/forms'
import { Event } from '../../types/event';
import { Plus } from 'react-bootstrap-icons'
import { EVENT, EVENT_CLASSIFICATION, EVENT_TYPES } from '../../API'

type EventsInfoProps = {}

type Classification = {
  Id: number
  Value: string
}
type Type = {
  Id: number
  Value: string
}

const initialEvent: Event = {
  Id: 0,
  Classification: '',
  Type: '',
  Criteria: '',
  Venue: '',
  Title: '',
  FromDate: '',
  ToDate: '',
  StartTime: '',
  EndTime: '',
  Provider: '',
  Deadline: '',
  Seats: 0,
  Instructors: '',
  Address: '',
  Location: '',
  Overview: '{"blocks":[],"entityMap":{}}',
  Audience: '{"blocks":[],"entityMap":{}}',
  Picture: '',
  Brochure: '',
  PreAssessment: '',
  PostAssessment: '',
  Data: '{}',
  CreatedBy: '',
  CreatedOn: new Date().toISOString(),
  UpdatedBy: '',
  UpdatedOn: new Date().toISOString(),
  Active: false,
  IsDeleted: false,
}

type response = {
  type: string
  message: string
  id: number
}

const EventInfo = ({ }: EventsInfoProps) => {
  const { id: eventId } = useParams()
  const [eventData, setEventData] = useState<Event>(initialEvent)
  const [checked, setChecked] = useState(eventData.Active)
  const [eventExtraData, setEventExtraData] = useState<{ [key: string]: string | Array<string> }>(JSON.parse(eventData.Data ?? '{}'))
  const [classificationData, setClassificationData] = useState<Array<Classification>>([])
  const [typeData, setTypeData] = useState<Array<Type>>([])
  const [loading, setLoading] = useState(true)
  const [showToast, setShowToast] = useState(false)
  const [toastMessage, setToastMessage] = useState('')

  const getData = async () => {
    const [classificationData, typeData] = await Promise.all([
      fetch(EVENT_CLASSIFICATION).then((response) => response.json()),
      fetch(EVENT_TYPES).then((response) => response.json()),
    ])
    setClassificationData(classificationData as Classification[])
    setTypeData(typeData as Type[])
    if (eventId === 'new') {
      setLoading(false)
      return
    }
    const eventData: Event = await fetch(EVENT`${eventId ?? ''}`)
      .then((response) => response.json())
    setEventData(eventData)
    setEventExtraData(JSON.parse(eventData.Data ?? '{}'))
    setChecked(eventData.Active)
    setLoading(false)
  }
  useEffect(() => { getData() }, [])

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    setLoading(true)
    event.preventDefault()
    const formData = new FormData(event.target as HTMLFormElement)
    formData.append('Overview', eventData.Overview)
    formData.append('Audience', eventData.Audience)
    formData.set('Id', `${eventData.Id}`)
    formData.set('Active', `${formData.get('Active') === 'on'}`)
    const Questions = formData.getAll('Questions')
      .filter((question) => question !== '')
    formData.set('Data', `${JSON.stringify({ ...eventExtraData, Questions })}`)
    const [responseEvent] = await Promise.all([
      submitForm(EVENT``, formData)
    ])
    if (responseEvent) setToastMessage('Event Saved Successfully ✅')
    else setToastMessage('Something went wrong ❌')
    setShowToast(true)
    await getData()
    setLoading(false)
  }

  const updateEventState = (id: string, value: string) => {
    setEventData(currentEventData => ({ ...currentEventData, [id]: value }))
  }
  const onChange = (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => {
    const { id, value } = event.target
    const extraData = ['PreAssessmentScore', 'PostAssessmentScore', 'SatisfactionSurveyScore']
    const isExtraData = extraData.includes(id)
    if (isExtraData) {
      setEventExtraData(currentExtraData => {
        const updatedExtraData = { ...currentExtraData, [id]: value }
        updateEventState('Data', JSON.stringify(updatedExtraData))
        return updatedExtraData
      })
    }
    else updateEventState(id, value)
  }

  if (loading) return <Spinner />
  return (
    <>
      <h1 className="display-6 mb-4 text-primary">Event Info</h1>
      <p>All fields are required unless indicated otherwise.</p>
      <Form className="mb-3" onSubmit={handleSubmit} method="POST">
        {/* ID AND TITLE */}
        <Row>
          <Col xs={2}>
            <Form.Group className="mb-3">
              <Form.Label>Id</Form.Label>
              <Form.Control
                className="disabled"
                defaultValue={eventData.Id == 0 ? "" : eventData.Id}
                id="Id"
                name="Id"
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3" onChange={onChange}>
              <Form.Label>Title</Form.Label>
              <Form.Control
                id="Title"
                defaultValue={eventData.Title}
                required={true}
                name="Title"
              />
            </Form.Group>
          </Col>
        </Row>
        {/* FROM AND TO AND DURATION */}
        <Row>
          <Col>
            <Form.Group className="mb-3" onChange={onChange}>
              <Form.Label>From</Form.Label>
              <Form.Control
                type="date"
                defaultValue={eventData.FromDate.split("T")[0]}
                required={true}
                id="FromDate"
                name="FromDate"
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3" onChange={onChange}>
              <Form.Label>To</Form.Label>
              <Form.Control
                id="ToDate"
                type="date"
                defaultValue={eventData.ToDate.split("T")[0]}
                required={true}
                name="ToDate"
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3" onChange={onChange}>
              <Form.Label>Start Time</Form.Label>
              <Form.Control
                id="StartTime"
                name="StartTime"
                type="time"
                defaultValue={
                  eventData.StartTime.split(":")[0] +
                  ":" +
                  eventData.StartTime.split(":")[1]
                }
                required={true}
              />
            </Form.Group>
          </Col>
        </Row>
        {/* PROVIDER AND DEADLINE AND TYPE */}
        <Row>
          <Col>
            <Form.Group className="mb-3" onChange={onChange}>
              <Form.Label>Provider</Form.Label>
              <Form.Control
                id="Provider"
                defaultValue={eventData.Provider}
                required={true}
                name="Provider"
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3" onChange={onChange}>
              <Form.Label>Registration Deadline</Form.Label>
              <Form.Control
                type="datetime-local"
                required={true}
                id="Deadline"
                defaultValue={eventData.Deadline}
                name="Deadline"
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3" onChange={onChange}>
              <Form.Label>End Time</Form.Label>
              <Form.Control
                id="EndTime"
                type="time"
                defaultValue={
                  eventData.EndTime.split(":")[0] +
                  ":" +
                  eventData.EndTime.split(":")[1]
                }
                required={true}
                name="EndTime"
              />
            </Form.Group>
          </Col>
        </Row>
        {/* MAX SEATS AND CLASSIFICATION AND INSTRUCTOR*/}
        <Row>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label>Type</Form.Label>
              <Form.Select
                name="Type"
                onChange={onChange}
                value={eventData.Type}
                id="Type"
              >
                {typeData.map((t, index) => (
                  <option key={`type-${index}`} value={t.Id.toString()}>
                    {t.Value}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label>Classification</Form.Label>
              <Form.Select
                onChange={onChange}
                name="classification"
                value={eventData.Classification}
                id="Classification"
              >
                {classificationData.map((c, index) => (
                  <option
                    key={`classification-${index}`}
                    value={c.Id.toString()}
                  >
                    {c.Value}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3" onChange={onChange}>
              <Form.Label>Max Seats</Form.Label>
              <Form.Control
                id="Seats"
                name="Seats"
                type="number"
                min="0"
                required={true}
                defaultValue={eventData.Seats}
              />
            </Form.Group>
          </Col>
        </Row>
        {/* ADDRESS AND GOOGLE MAPS LOCATION */}
        <Row>
          <Col>
            <Form.Group className="mb-3" onChange={onChange}>
              <Form.Label>Instructor(s)</Form.Label>
              <Form.Control
                id="Instructors"
                name="Instructors"
                required={true}
                defaultValue={eventData.Instructors}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3" onChange={onChange}>
              <Form.Label>Venue</Form.Label>
              <Form.Control
                id="Venue"
                name="Venue"
                required={true}
                defaultValue={eventData.Venue}
              />
            </Form.Group>
          </Col>
        </Row>
        {/* ADDRESS AND GOOGLE MAPS LOCATION */}
        <Row>
          <Col>
            <Form.Group className="mb-3" onChange={onChange}>
              <Form.Label>Address</Form.Label>
              <Form.Control
                id="Address"
                name="Address"
                required={true}
                defaultValue={eventData.Address}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3" onChange={onChange}>
              <Form.Label>Google Maps Location</Form.Label>
              <Form.Control
                id="Location"
                name="Location"
                required={true}
                defaultValue={eventData.Location}
              />
            </Form.Group>
          </Col>
        </Row>
        {/* OVERVIEW */}
        <Row>
          <Form.Group className="mb-3" onChange={onChange}>
            <Form.Label>
              Overview, Description, and Program Objectives
            </Form.Label>
            <EditorComponent
              initialState={eventData.Overview}
              setEditorDataState={updateEventState}
              id="Overview"
            />
          </Form.Group>
        </Row>
        {/* WHO SHOULD ATTEND */}
        <Row>
          <Form.Group className="mb-3" onChange={onChange}>
            <Form.Label>Who Should Attend?</Form.Label>
            <EditorComponent
              initialState={eventData.Audience}
              setEditorDataState={updateEventState}
              id="Audience"
            />
          </Form.Group>
        </Row>
        {/* ATTACHMENTS */}
        <p>
          Please make sure your file size is less than 5 MB to successfully
          upload.
        </p>
        <Row>
          <Col>
            <FileInput
              formLabel="Logo Picture (16 by 9)"
              name="Picture"
              fileId={eventData.Picture}
              accept="image/*"
              hasFile={
                eventData.Picture !== "" &&
                eventData.Picture !== "00000000-0000-0000-0000-000000000000"}
            />
          </Col>
          <Col>
            <FileInput
              formLabel="Brochure"
              name="Brochure"
              fileId={eventData.Brochure}
              accept="image/*,.pdf"
              hasFile={
                eventData.Brochure !== "" &&
                eventData.Brochure !== "00000000-0000-0000-0000-000000000000"}
            />
          </Col>
        </Row>
        {/* <Row>
          <Col>
            <FileInput
              formLabel="Pre-Assessment (optional)"
              name="Preassessment"
              fileId={eventData.PreAssessment}
              accept=".doc, .docx, .ppt, .pptx, .pdf"
              hasFile={
                eventData.PreAssessment !== "" &&
                eventData.PreAssessment !== "00000000-0000-0000-0000-000000000000"
              }
              required={false}
            />
          </Col>
          <Col>
            <FileInput
              formLabel="Post-Assessment (optional)"
              name="Postassessment"
              fileId={eventData.PostAssessment}
              accept=".doc, .docx, .ppt, .pptx, .pdf"
              hasFile={
                eventData.PostAssessment !== "" &&
                eventData.PostAssessment !== "00000000-0000-0000-0000-000000000000"
              }
              required={false}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <FileInput
              formLabel="Application Criteria (optional)"
              name="Criteria"
              fileId={eventData.Criteria}
              accept=".doc, .docx, .ppt, .pptx, .pdf"
              hasFile={
                eventData.Criteria !== "" &&
                eventData.Criteria !== "00000000-0000-0000-0000-000000000000"
              }
              required={false}
            />
          </Col>
          <Col></Col>
        </Row> */}
        <Row>
          <Col>
            <Form.Group className="mb-3" onChange={onChange}>
              <Form.Label>Pre-Assessment Score</Form.Label>
              <Form.Control
                id="PreAssessmentScore"
                name="PreAssessmentScore"
                defaultValue={eventExtraData.PreAssessmentScore}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3" onChange={onChange}>
              <Form.Label>Post-Assessment Score</Form.Label>
              <Form.Control
                id="PostAssessmentScore"
                name="PostAssessmentScore"
                defaultValue={eventExtraData.PostAssessmentScore}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group className="mb-3" onChange={onChange}>
              <Form.Label>Satisfaction Survey Score</Form.Label>
              <Form.Control
                id="SatisfactionSurveyScore"
                name="SatisfactionSurveyScore"
                defaultValue={eventExtraData.SatisfactionSurveyScore}
              />
            </Form.Group>
          </Col>
          <Col></Col>
        </Row>
        <Row>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label>Questions</Form.Label>
              <Button variant="primary" className="ms-3 mb-1" onClick={() => {
                setEventExtraData(currentEventExtraData => {
                  const updatedEventExtraData = { ...currentEventExtraData }
                  updatedEventExtraData.Questions ||= [] as Array<string>
                  (updatedEventExtraData.Questions as Array<string>).push("")
                  return updatedEventExtraData
                })
              }}><Plus /></Button>
              {eventExtraData.Questions?.length
                ?
                (eventExtraData.Questions as Array<string>).map((question, index) =>
                  <Form.Control
                    key={index}
                    id={`Questions${index}`}
                    name="Questions"
                    defaultValue={question}
                    className="mb-2"
                  />)
                :
                < Form.Control
                  id="Questions0"
                  name="Questions"
                  defaultValue={eventExtraData.Questions?.[0]}
                />}
            </Form.Group>
          </Col>
        </Row>
        {/* IS ACTIVE */}
        <Row>
          <Form.Group className="mb-3">
            <Form.Check
              type="checkbox"
              label="Active"
              id="active"
              name="Active"
              checked={checked}
              onChange={() => setChecked(!checked)}
            />
          </Form.Group>
        </Row>
        <Form.Control type="hidden" id="Data" name="Data" defaultValue={eventData.Data ?? '{}'} />
        <Button variant="primary" type="submit">
          Save
        </Button>
      </Form>
      <ToastContainer position="bottom-end" className="p-3">
        <Toast
          className="ms-auto"
          show={showToast}
          onClose={() => setShowToast(false)}
          delay={3000}
          autohide
        >
          <Toast.Header>
            <strong className="me-auto">KFAS Learn</strong>
          </Toast.Header>
          <Toast.Body>{toastMessage}</Toast.Body>
        </Toast>
      </ToastContainer>
    </>
  )
}

export default EventInfo

import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import BootstrapTable from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import Toast from 'react-bootstrap/Toast'
import ToastContainer from 'react-bootstrap/ToastContainer'
import BootstrapSpinner from 'react-bootstrap/Spinner'
import Badge from 'react-bootstrap/esm/Badge'
import Spinner from '../../components/Spinner/Spinner'
import Filter from '../../components/Filter/Filter'
import { useParams } from 'react-router'
import { Applicant, ApplicantData } from '../../types/applicant'
import { downloadCSVFile, escapeData } from '../../utils/csv'
import { submitForm } from '../../utils/forms'
import { ArrowClockwise, Download, Save } from 'react-bootstrap-icons'
import {
  ColumnDef,
  getCoreRowModel,
  SortingState,
  getSortedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable
} from '@tanstack/react-table'
import { allApplicantsTableColumns } from '../../data/AllApplicantsTableColumns'
import { parseBooleanOrNull } from '../../utils/generic'
import Table from '../../components/Table/Table'
import Toolbar from '../../components/Toolbar/Toolbar'
import { ACCEPTED_APPLICANT_COUNT, APPLICANTS_APPROVAL, APPLICANTS_DETAILS, APPLICANTS_SCORE, DOWNLOAD, EVENT_ACCEPTED_APPLICANTS, EVENT_CAPACITY, EVENT_TITLE, GET_APPLICANTS } from '../../API'

type ApplicantApproval = {
  Id: number
  Status: string
}
type ApplicantDetails = {
  Id: number
  Details: string
}
type ApplicantScore = {
  Id: number
  Score: number
}
type Props = {}

const AllApplicants = (props: Props) => {
  //hooks
  const [loading, setLoading] = useState(true)
  const [eventName, setEventName] = useState('')
  const [capacity, setCapacity] = useState(0)
  const [acceptedApplicantsNumber, setAcceptedApplicantsNumber] = useState(0)
  const [applicantApprovals, setApplicantApprovals] = useState<Array<ApplicantApproval>>([])
  const [applicantDetails, setApplicantDetails] = useState<Array<ApplicantDetails>>([])
  const [applicantScores, setApplicantScores] = useState<Array<ApplicantScore>>([])
  const [disabled, setDisabled] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [applicants, setApplicants] = useState<Array<Applicant>>([])
  const [showToast, setShowToast] = useState(false)
  const [toastMessage, setToastMessage] = useState('')
  const [sorting, setSorting] = React.useState<SortingState>([])
  const { id: eventId } = useParams()

  const getApplicants = async () => {
    const [
      applicationsData,
      capacityData,
      acceptedData,
      eventNameData,
      acceptedApplicantsNum,
    ] = await Promise.all([
      fetch(GET_APPLICANTS`${eventId ?? ''}`)
        .then(response => response.json()),
      fetch(EVENT_CAPACITY`${eventId ?? ''}`)
        .then(response => response.json()),
      fetch(EVENT_ACCEPTED_APPLICANTS`${eventId ?? ''}`)
        .then(response => response.json()),
      fetch(EVENT_TITLE`${eventId ?? ''}`)
        .then(response => response.text()),
      fetch(ACCEPTED_APPLICANT_COUNT`${eventId ?? ''}`)
        .then(response => response.text()),
    ])
    setEventName(eventNameData)
    setApplicants(
      applicationsData.map((application: Applicant) => {
        if (application.Data) {
          application.Data = JSON.parse(application.Data as string)
        }
        return application
      })
    )
    setCapacity(capacityData)
    setAcceptedApplicantsNumber(parseInt(acceptedApplicantsNum))
    setApplicantApprovals([])
    setApplicantDetails([])
    setApplicantScores([])
    setLoading(false)
  }
  useEffect(() => { getApplicants() }, [])

  const reload = async () => {
    setLoading(true)
    await getApplicants()
    setLoading(false)
  }

  const onStatusChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedId = Number(event.currentTarget.getAttribute('data-id'))
    const value = event.currentTarget.value
    setApplicantApprovals(approvals => {
      let status = '0'
      switch (value) {
        case 'Accepted':
          status = '2'
          break
        case 'Rejected':
          status = '3'
          break
        case 'Withdrawn':
          status = '4'
          break
        case 'Waitlisted':
          status = '5'
          break
        case 'Pending':
        default:
          status = '1'
          break
      }
      const approvalExists = approvals
        .find(approval => approval.Id === selectedId)
      const updatedApplicantApprovals = approvalExists
        ? approvals
          .map(approval => {
            if (approval.Id === selectedId)
              approval.Status = status
            return approval
          })
        : [...approvals, { Id: selectedId, Status: status }]
      return updatedApplicantApprovals
    })
  }
  const onDetailsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedId = Number(event.currentTarget.dataset.id)
    const value = event.currentTarget.value
    setApplicantDetails(details => {
      const detailExists = details
        .find(applicantDetail => applicantDetail.Id === selectedId)
      const updatedApplicantDetails = detailExists
        ? details
          .map(applicantDetail => {
            if (applicantDetail.Id === selectedId)
              applicantDetail.Details = value
            return applicantDetail
          })
        : [...details, { Id: selectedId, Details: value }]
      return updatedApplicantDetails
    })
  }
  const onScoreChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedId = Number(event.currentTarget.dataset.id)
    const value = Number(event.currentTarget.value)
    setApplicantScores(scores => {
      const scoreExists = scores
        .find(applicantScore => applicantScore.Id === selectedId)
      const updatedApplicantScore = scoreExists
        ? scores
          .map(applicantScore => {
            if (applicantScore.Id === selectedId)
              applicantScore.Score = value
            return applicantScore
          })
        : [...scores, { Id: selectedId, Score: value }]
      return updatedApplicantScore
    })
  }

  const columns = React.useMemo<ColumnDef<Applicant>[]>(
    () => allApplicantsTableColumns({ onStatusChange, onDetailsChange, onScoreChange }), [])
  const table = useReactTable({
    data: applicants,
    columns,
    state: { sorting, },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  })

  const generateCSV = () => {
    let lines = ''
    applicants.forEach((applicant, index) => {
      const flattened =
        applicant.Data && typeof applicant.Data !== 'string'
          ? { ...applicant, ...applicant.Data }
          : { ...applicant }
      delete flattened.Data
      const tuples = Object.entries(flattened)
      if (index === 0) {
        for (const [key, value] of tuples) {
          if (key === 'Approved')
            lines += `"${escapeData('HR Approval')}",`
          else if (key === 'Status')
            lines += `"${escapeData('KFAS Status')}",`
          else
            lines += `"${escapeData(key)}",`
        }
        lines += '\n'
      }
      for (const [key, value] of tuples) {
        if (
          key === 'Video' ||
          key === 'TrainingApproval' ||
          key === 'NominationLetter' ||
          key === 'OrganizationChart' ||
          key === 'ValidVisa' ||
          key === 'Passport' ||
          key === 'VaccinationProof' ||
          key === 'UniversityApplicationForm' ||
          key === 'CV'
        )
          lines +=
            value !== '00000000-0000-0000-0000-000000000000'
              ? `"https://learn.kfas.org.kw${escapeData(DOWNLOAD`${value as string}`)}",`
              : `"Unsubmitted",`
        else if (key === 'OrganizationHRRepresentativesEmails')
          lines += `"${escapeData((value as string[]).join(';'))}",`
        else if (key === 'Profile')
          lines += `"${escapeData(JSON.stringify(value))}",`
        else if (key === 'Approved')
          lines += value === null
            ? `"Pending",`
            : value
              ? `"Approved",`
              : `"Rejected",`
        else lines += value ? `"${value}",` : `"",`
      }
      lines += '\n'
    })
    const today = new Date()
    const date = `${today.getFullYear()}${today.getMonth() + 1}${today.getDate()}`
    downloadCSVFile(lines, `${date} - ${eventName} - All Applicants`)
    setToastMessage('Download Successful✅')
    setShowToast(true)
  }

  const finalizeChanges = async () => {
    setLoading(true)
    const [
      responseStatus,
      responseDetails,
      responseScore,
      acceptedApplicantsNum,
    ] = await Promise.all([
      submitForm(APPLICANTS_APPROVAL, applicantApprovals),
      submitForm(APPLICANTS_DETAILS, applicantDetails),
      submitForm(APPLICANTS_SCORE, applicantScores),
      fetch(ACCEPTED_APPLICANT_COUNT`${eventId ?? ''}`)
        .then(response => response.text()),
    ])
    if (responseStatus || responseDetails || responseScore) {
      setShowModal(false)
      setAcceptedApplicantsNumber(parseInt(acceptedApplicantsNum))
      setToastMessage('Changes Finalized✅')
      setShowToast(true)
      setApplicantDetails([])
      setApplicantScores([])
      await getApplicants()
    } else {
      setShowModal(false)
      setToastMessage('Something went wrong❌')
      setShowToast(true)
    }
    setLoading(false)
  }

  if (loading) return <Spinner />
  return (
    <>
      <div className="d-flex justify-content-between align-items-center position-relative mb-2">
        <h1 className="display-6 text-primary">All Applicants - {eventName}</h1>
        <Toolbar>
          <Button onClick={reload} title="Reload">
            <ArrowClockwise />
            <div><small>Reload</small></div>
          </Button>
          <Button onClick={generateCSV}>
            <Download />
            <div><small>Download</small></div>
          </Button>
          <Button onClick={() => setShowModal(true)}>
            <Save />
            <div><small>Finalize</small></div>
          </Button>
        </Toolbar>
      </div>
      <BootstrapTable bordered hover responsive>
        <tbody>
          <tr>
            <td>Seat Capacity</td>
            <td className="text-center">{capacity}</td>
          </tr>
          <tr>
            <td>Received Applicants</td>
            <td className="text-center">{applicants.length}</td>
          </tr>
          <tr>
            <td>Accepted Applicants</td>
            <td className="text-center">{acceptedApplicantsNumber}</td>
          </tr>
        </tbody>
      </BootstrapTable>
      <Table table={table} />
      <ToastContainer className="p-3 position-fixed bottom-0 end-0">
        <Toast
          className="ms-auto"
          show={showToast}
          onClose={() => setShowToast(false)}
          delay={3000}
          autohide
        >
          <Toast.Header>
            <strong className="me-auto">KFAS Learn</strong>
          </Toast.Header>
          <Toast.Body>{toastMessage}</Toast.Body>
        </Toast>
      </ToastContainer>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Finalize Changes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to finalize the changes? This action cannot be
          undone. Acceptance/Rejection emails will be mass sent.
          {loading && (
            <BootstrapSpinner animation="border" className="d-block m-auto" />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={finalizeChanges}>
            Finalize
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default AllApplicants

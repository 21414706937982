import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { PersonCheck, People } from 'react-bootstrap-icons'
import Spinner from '../../components/Spinner/Spinner'
import DashboardCard from '../../components/DashboardCard/DashboardCard'
import { EVENT_TITLE } from '../../API'

type ApplicantsDashboardProps = {}

const ApplicantsDashboard = ({ }: ApplicantsDashboardProps) => {
  const { id } = useParams()
  const [title, setTitle] = useState('')
  const [loading, setLoading] = useState(true)

  const getEventName = async () => {
    const [title] = await Promise.all([
      fetch(EVENT_TITLE`${id ?? ''}`).then(response => response.text()),
    ])
    setTitle(title)
    setLoading(false)
  }
  useEffect(() => { getEventName() }, [])

  if (loading) return (<Spinner />)
  return (
    <>
      <h1 className="display-6 mb-4 text-primary text-center text-md-start border-bottom">Applicants - {title}</h1>
      <div className="d-flex align-items-stretch justify-content-center justify-content-md-start flex-wrap gap-4 text-center mb-5">
        <DashboardCard title="All Applicants" target={`/admin/event-info/${id}/all-applicants`}>
          <People size={32} />
        </DashboardCard>
        <DashboardCard title="Accepted Applicants" target={`/admin/event-info/${id}/accepted-applicants`}>
          <PersonCheck size={32} />
        </DashboardCard>
      </div>
    </>
  )
}

export default ApplicantsDashboard

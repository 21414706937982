import * as React from 'react'
import { APP_FILE } from '../../API'

let count = 0
type Data = Record<string, any>
interface Props {
  data: Data
}

const TraverseObject = (data: Data) => {
  return Object.keys(data).map((key) => {
    const value = data[key]
    if (typeof value === 'object') {
      if (key !== 'Questions' && key !== 'Answers')
        return (
          <React.Fragment key={`${key}${count++}`}>
            {TraverseObject(value as Data)}
          </React.Fragment>
        )
      if (key === 'Questions')
        return value.map((question: string, index: number) => (
          <div key={`${key}${index}`} className="border-bottom pt-2">
            <h3>{question}</h3>
            <p>{data['Answers'][index]}</p>
          </div>
        ))
      if (key === 'Answers')
        return (<React.Fragment key={`${key}${count++}`}></React.Fragment>)
    }
    else if (typeof value === 'string' && isGUID(value)) {
      return (
        <div key={`${key}${count++}`} className="border-bottom pt-2">
          <h3>{key.split(/(?=[A-Z])/).join(' ')}</h3>
          <a href={APP_FILE`${value}`} download><p>Download</p></a>
        </div>
      )
    }
    return (
      <div key={`${key}${count++}`} className="border-bottom pt-2">
        <h3>{key.split(/(?=[A-Z])/).join(' ')}</h3>
        <p>{value ?? 0}</p>
      </div>
    )
  })
}

function isGUID(value: string): boolean {
  const guidPattern = /^[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}$/
  return guidPattern.test(value)
}

const ObjectDisplay: React.FC<Props> = ({ data }) => {
  return <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>{TraverseObject(data)}</div>
}

export default ObjectDisplay

import * as React from 'react'
import { useEffect, useState, createRef } from 'react'
import Spinner from '../../components/Spinner/Spinner'
import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router'
import data from '../shared/Data.en.json'
import ObjectDisplay from './ObjectDisplay'
import { APP_DATA } from '../../API'

interface ApplicationData {
  [key: string]: string | number
}
type Props = {}
type ObjectKey = keyof typeof data

const EmployeeApplication = (props: Props) => {
  const { id: AppId } = useParams()
  const navigate = useNavigate()
  const [applicationData, setApplicationData] = useState<ApplicationData>({})
  const [loading, setLoading] = useState(true)
  const [alert, setAlert] = useState<string>('')

  useEffect(() => {
    const loadData = async () => {
      const [appData] = await Promise.all([
        fetch(APP_DATA`${AppId ?? ''}`)
          .then((response) => response.json())
      ])
      setApplicationData(appData)
      if (appData) setLoading(false)
      else navigate(-1)
    }
    loadData()
  }, [AppId])

  if (loading) return <Spinner />
  return (
    <>
      <h1 className="display-6 mb-4 text-primary">Employee Application</h1>
      {alert.length > 0 && (
        <div
          className="alert alert-danger"
          role="alert"
          dangerouslySetInnerHTML={{ __html: alert }}
        />
      )}
      {/**DISPLAY DATA */}
      <ObjectDisplay data={applicationData} />
    </>
  )
}

function isGUID(value: string): boolean {
  const guidPattern = /^[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}$/
  return guidPattern.test(value)
}

export default EmployeeApplication

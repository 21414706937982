import React, { useEffect, useState } from 'react'
import Spinner from '../../components/Spinner/Spinner'
import { Report } from '../../types/report'
import Toolbar from '../../components/Toolbar/Toolbar'
import { Button, Row, Col, Container } from 'react-bootstrap'
import { ArrowClockwise } from 'react-bootstrap-icons'
import ReportCard from '../../components/ReportCard/ReportCard'
import { Chart as ChartJS, ArcElement, Tooltip, Legend, ChartData } from 'chart.js'
import { Pie } from 'react-chartjs-2'
import { ADMIN_REPORT } from '../../API'

ChartJS.register(ArcElement, Tooltip, Legend)

type ReportsProps = {}

const INITIAL_CHART_DATA: ChartData<"pie", number[], unknown> = {
  labels: [],
  datasets: [
    {
      label: '',
      data: [],
      backgroundColor: [],
      borderColor: [],
      borderWidth: 1,
    },
  ],
}
const GREEN = 'rgba(75, 192, 192, 0.2)'
const GREEN_BORDER = 'rgba(75, 192, 192, 1)'
const RED = 'rgba(255, 99, 132, 0.2)'
const RED_BORDER = 'rgba(255, 99, 132, 1)'
const ORANGE = 'rgba(255, 159, 64, 0.2)'
const ORANGE_BORDER = 'rgba(255, 159, 64, 1)'
const PINK = 'rgba(255, 86, 203, 0.2)'
const PINK_BORDER = 'rgba(255, 86, 203, 1)'
const BLUE = 'rgba(54, 162, 235, 0.2)'
const BLUE_BORDER = 'rgba(54, 162, 235, 1)'


const Reports = ({ }: ReportsProps) => {
  const [loading, setLoading] = useState(true)
  const [report, setReport] = useState<Report>()
  const [totalApplications, setTotalApplications] = useState<number>(0)
  const [totalApprovedApplications, setTotalApprovedApplications] = useState<number>(0)
  const [totalRejectedApplications, setTotalRejectedApplications] = useState<number>(0)
  const [totalPendingApplications, setTotalPendingApplications] = useState<number>(0)
  const [applicationsChartData, setApplicationsChartData] = useState<ChartData<"pie", number[], unknown>>(INITIAL_CHART_DATA)

  const [totalIndividuals, setTotalIndividuals] = useState<number>(0)
  const [totalVerifiedIndividuals, setTotalVerifiedIndividuals] = useState<number>(0)
  const [totalUnverifiedIndividuals, setTotalUnverifiedIndividuals] = useState<number>(0)
  const [totalFemaleIndividuals, setTotalFemaleIndividuals] = useState<number>(0)
  const [totalMaleIndividuals, setTotalMaleIndividuals] = useState<number>(0)
  const [individualVerificationChartData, setIndividualVerificationChartData] = useState<ChartData<"pie", number[], unknown>>(INITIAL_CHART_DATA)
  const [individualGenderChartData, setIndividualGenderChartData] = useState<ChartData<"pie", number[], unknown>>(INITIAL_CHART_DATA)

  const [totalOrganizations, setTotalOrganizations] = useState<number>(0)
  const [totalApprovedOrganizations, setTotalApprovedOrganizations] = useState<number>(0)
  const [totalRejectedOrganizations, setTotalRejectedOrganizations] = useState<number>(0)
  const [totalPendingOrganizations, setTotalPendingOrganizations] = useState<number>(0)
  const [totalRepresentatives, setTotalRepresentatives] = useState<number>(0)
  const [organizationChartData, setOrganizationChartData] = useState<ChartData<"pie", number[], unknown>>(INITIAL_CHART_DATA)

  const [totalArticles, setTotalArticles] = useState<number>(0)
  const [totalSubscribers, setTotalSubscribers] = useState<number>(0)

  const getReports = async () => {
    const [reportsData] = await Promise.all([fetch(ADMIN_REPORT).then(response => response.json())])
    setReport(reportsData)
    setLoading(false)
  }
  useEffect(() => { getReports() }, [])

  const generateReport = async () => {
    if (!report) { return }

    const totalApplications = report.Applications.length
    const totalApprovedApplications = report.Applications.filter(application => application.Approved === true).length
    const totalRejectedApplications = report.Applications.filter(application => application.Approved === false).length
    const totalPendingApplications = report.Applications.filter(application => application.Approved === null).length
    setTotalApplications(totalApplications)
    setTotalApprovedApplications(totalApprovedApplications)
    setTotalRejectedApplications(totalRejectedApplications)
    setTotalPendingApplications(totalPendingApplications)
    setApplicationsChartData({
      labels: ['Approved', 'Rejected', 'Pending'],
      datasets: [{
        label: '# of Applications',
        data: [totalApprovedApplications, totalRejectedApplications, totalPendingApplications],
        backgroundColor: [GREEN, RED, ORANGE],
        borderColor: [GREEN_BORDER, RED_BORDER, ORANGE_BORDER],
        borderWidth: 1,
      }],
    })

    const totalIndividuals = report.Profiles.length
    const totalVerifiedIndividuals = report.Profiles.filter(individual => individual.Verified).length
    const totalUnverifiedIndividuals = report.Profiles.filter(individual => !individual.Verified).length
    const totalFemaleIndividuals = report.Profiles.filter(individual => individual.Gender).length
    const totalMaleIndividuals = report.Profiles.filter(individual => !individual.Gender).length
    setTotalIndividuals(totalIndividuals)
    setTotalVerifiedIndividuals(totalVerifiedIndividuals)
    setTotalUnverifiedIndividuals(totalUnverifiedIndividuals)
    setTotalFemaleIndividuals(totalFemaleIndividuals)
    setTotalMaleIndividuals(totalMaleIndividuals)
    setIndividualVerificationChartData({
      labels: ['Verified', 'Unverified'],
      datasets: [{
        label: '# of Individuals',
        data: [totalVerifiedIndividuals, totalUnverifiedIndividuals],
        backgroundColor: [GREEN, RED],
        borderColor: [GREEN_BORDER, RED_BORDER],
        borderWidth: 1,
      }],
    })
    setIndividualGenderChartData({
      labels: ['Female', 'Male'],
      datasets: [{
        label: '# of Individuals',
        data: [totalFemaleIndividuals, totalMaleIndividuals],
        backgroundColor: [PINK, BLUE],
        borderColor: [PINK_BORDER, BLUE_BORDER],
        borderWidth: 1,
      }],
    })

    const totalOrganizations = report.Organizations.length
    const totalApprovedOrganizations = report.Organizations.filter(organization => organization.IsApproved === true).length
    const totalRejectedOrganizations = report.Organizations.filter(organization => organization.IsApproved === false).length
    const totalPendingOrganizations = report.Organizations.filter(organization => organization.IsApproved === null).length
    const totalRepresentatives = report.Representatives.length
    setTotalOrganizations(totalOrganizations)
    setTotalApprovedOrganizations(totalApprovedOrganizations)
    setTotalRejectedOrganizations(totalRejectedOrganizations)
    setTotalPendingOrganizations(totalPendingOrganizations)
    setTotalRepresentatives(totalRepresentatives)
    setOrganizationChartData({
      labels: ['Approved', 'Rejected', 'Pending'],
      datasets: [{
        label: '# of Organizations',
        data: [totalApprovedOrganizations, totalRejectedOrganizations, totalPendingOrganizations],
        backgroundColor: [GREEN, RED, ORANGE],
        borderColor: [GREEN_BORDER, RED_BORDER, ORANGE_BORDER],
        borderWidth: 1,
      }],
    })

    const totalArticles = report.Articles.length
    const totalSubscribers = report.Subscribers.length
    setTotalArticles(totalArticles)
    setTotalSubscribers(totalSubscribers)
  }
  useEffect(() => { generateReport() }, [report])

  const reload = async () => {
    setLoading(true)
    await getReports()
    setLoading(false)
  }


  if (loading) return (<Spinner />)
  return (
    <>
      <div className="d-flex flex-column flex-sm-row justify-content-between align-items-center position-relative mb-2">
        <h1 className="display-6 text-primary">Reports</h1>
        <Toolbar>
          <Button onClick={reload} title="Reload" className="">
            <ArrowClockwise />
            <div><small>Reload</small></div>
          </Button>
        </Toolbar>
      </div>
      <h2 className="mb-4 text-primary text-center text-md-start border-bottom">Applications</h2>
      <div className="d-flex align-items-stretch justify-content-center justify-content-md-start flex-wrap gap-4 text-center mb-5">
        <Container>
          <Row>
            <Col>
              <Pie data={applicationsChartData} />
            </Col>
            <Col>
              <ReportCard title="Total Approved Applications">{totalApprovedApplications}</ReportCard>
              <ReportCard title="Total Rejected Applications">{totalRejectedApplications}</ReportCard>
              <ReportCard title="Total Pending Applications">{totalPendingApplications}</ReportCard>
            </Col>
          </Row>
          <Row>
            <Col>
              <ReportCard title="Total Applications">{totalApplications}</ReportCard>
            </Col>
          </Row>
        </Container>
      </div>
      <h2 className="mb-4 text-primary text-center text-md-start border-bottom">Individuals</h2>
      <div className="d-flex align-items-stretch justify-content-center justify-content-md-start flex-wrap gap-4 text-center mb-5">
        <Container>
          <Row>
            <Col>
              <Pie data={individualVerificationChartData} />
            </Col>
            <Col>
              <ReportCard title="Total Verified Individuals">{totalVerifiedIndividuals}</ReportCard>
              <ReportCard title="Total Unverified Individuals">{totalUnverifiedIndividuals}</ReportCard>
            </Col>
          </Row>
          <Row>
            <Col>
              <Pie data={individualGenderChartData} />
            </Col>
            <Col>
              <ReportCard title="Total Female Individuals">{totalFemaleIndividuals}</ReportCard>
              <ReportCard title="Total Male Individuals">{totalMaleIndividuals}</ReportCard>
            </Col>
          </Row>
          <Row>
            <Col>
              <ReportCard title="Total Individuals">{totalIndividuals}</ReportCard>
            </Col>
          </Row>
        </Container>
      </div>
      <h2 className="mb-4 text-primary text-center text-md-start border-bottom">Organizations</h2>
      <div className="d-flex align-items-stretch justify-content-center justify-content-md-start flex-wrap gap-4 text-center mb-5">
        <Container>
          <Row>
            <Col>
              <Pie data={organizationChartData} />
            </Col>
            <Col>
              <ReportCard title="Total Approved Organizations">{totalApprovedOrganizations}</ReportCard>
              <ReportCard title="Total Rejected Organizations">{totalRejectedOrganizations}</ReportCard>
              <ReportCard title="Total Pending Organizations">{totalPendingOrganizations}</ReportCard>
            </Col>
          </Row>
          <Row>
            <Col>
              <ReportCard title="Total Organizations">{totalOrganizations}</ReportCard>
            </Col>
          </Row>
          <Row>
            <Col>
              <ReportCard title="Total Representatives">{totalRepresentatives}</ReportCard>
            </Col>
          </Row>
        </Container>
      </div>
      <h2 className="mb-4 text-primary text-center text-md-start border-bottom">General</h2>
      <div className="d-flex align-items-stretch justify-content-center justify-content-md-start flex-wrap gap-4 text-center mb-5">
        <Container>
          <Row>
            <Col>
              <ReportCard title="Total Articles">{totalArticles}</ReportCard>
              <ReportCard title="Total Subscribers">{totalSubscribers}</ReportCard>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default Reports
